@import '../../assets/mixin.less';

//组件样式 公用
.layout-page {
	height: 100vh;
	background-color: @color-brand  !important;

	.layout-right {
		background-color: #FFFFFF;
		border-top-left-radius: 30px;
		min-width: 800px;
	}
}

// 弹框样式
.ant-modal-content {
	border-radius: 6px !important;
}

.ant-modal-close {
	width: 30px;
	height: 30px;
	left: -12px;
	top: -12px !important;
	background-color: white !important;
	border-radius: 50%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

	.ant-modal-close-x {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 12px;
			height: 13px;
		}
	}
}

// 弹框标题
.modal-title-box {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.modal-title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 24px;
	}

	.modal-title-explain {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 24px;

		.head {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;
			margin-right: 8px;
			border-width: 1px;
			border-style: solid;
		}

		.name {
			font-size: 12px;
			margin-right: 16px;
		}

		.date {
			font-size: 12px;
			color: @color-gray-2;
		}
	}
}

.green-border {
	border-color: @color-green;
}

.red-border {
	border-color: @color-red;
}

.purple-border {
	border-color: @color-purple;
}

.green-color {
	color: @color-green  !important;
}

.red-color {
	color: @color-red  !important;
}

.purple-color {
	color: @color-purple  !important;
}

.grayish-blue-color {
	color: @color-grayish-blue  !important;
}

.blue-color {
	color: @color-blue  !important;
}


.upload-type {
	width: 175px;
	height: 36px;
	border: 1px solid @color-gray-1;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	margin: 0px auto 30px;

	.add-type {
		width: 50%;
		height: 100%;
		color: @color-brand;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.add-type-pick {
		color: white;
		background-color: @color-brand;
	}
}

// 上传附件

.add-file{
	margin-bottom: 24px;
	padding: 0 20px;
}
.ant-upload-drag {
	width: 100% !important;
	height: 98px !important;
	min-width: 375px !important;
	padding: 0 !important;
	margin: 0 auto 10px !important;
	border-radius: 8px !important;
	background-color: white !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.ant-upload-drag-container {
	height: 100%;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.icon-box {
	width: 48px;
	margin-right: 20px;
	margin-left: -10px;

	img {
		width: 100%;
	}
}

.text-box {
	text-align: center;

	.add-file-title {
		font-size: 16px;
		color: @color-gray-2;
		font-weight: normal;
	}

	.add-file-explain {
		font-size: 12px;
		color: @color-gray-2;
		margin-bottom: 0;
	}
}

// 下拉框
.ant-select-selector {
	padding: 0 !important;
}

// 输入框
.ant-input {
	border-radius: 8px !important;
	border: 1px solid @color-gray-1  !important;
	background-color: @color-gray-0  !important;
}

// 按钮
.ant-btn {
	border: none !important;
	border-radius: 6px !important;
	text-shadow: none !important;
}

// 不显示滚动条
.object-list::-webkit-scrollbar,
.content::-webkit-scrollbar,
.object-members-list::-webkit-scrollbar,
.object-dynamic-list::-webkit-scrollbar,
.object-document-list::-webkit-scrollbar {
	width: 0px;
	scrollbar-width: none;
}

.yellow {
	background-color: @color-yellow  !important;
}

.blue {
	background-color: @color-blue  !important;
}

.red {
	background-color: @color-red  !important;
}

.purple {
	background-color: @color-purple  !important;
}

.green {
	background-color: @color-green  !important;
}

.grayish-blue {
	background-color: @color-grayish-blue  !important;
}

.yellow:hover {
	box-shadow: 0 6px 12px rgba(255, 196, 0, 0.5);
}

.blue:hover {
	box-shadow: 0 6px 12px rgba(64, 93, 255, 0.5);
}

.red:hover {
	box-shadow: 0 6px 12px rgba(233, 82, 63, 0.5);
}

.purple:hover {
	box-shadow: 0 6px 12px rgba(106, 67, 237, 0.5);
}

.green:hover {
	box-shadow: 0 6px 12px rgba(53, 193, 85, 0.5);
}

.grayish-blue:hover {
	box-shadow: 0 6px 12px rgba(43, 44, 70, 0.5);
}


// 页面样式开始
.manageCube-page {
	background-color: @color-brand  !important;

	.sider {
		border-radius: 0 10px 10px 0;
	}

	.header {
		margin-left: 10px;
		padding: 0 20px;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 100px;

		.show-silder-btn {
			height: 24px;
			line-height: 24px;

			.icon {
				color: #000;
				font-size: 24px;
			}
		}

		.hearder-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				margin-left: 20px;
				border-radius: 6px;
				text-shadow: none;

				span {
					line-height: 1.7;
					max-width: 150px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				border-radius: 100px;

				.text {
					font-size: 12px;
					margin-right: 10px;
				}

				.feedback {
					height: 32px;
					padding: 0 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid @color-brand;
					border-radius: 6px;
					font-size: 13px;
					margin-right: 70px;
					cursor: pointer;
					transition: all 0.2s;
				}

				.feedback:hover {
					background-color: @color-grayish-blue;
					color: #fff;
				}

				.online-user {
					width: auto;
					height: 64px;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					border-radius: 100px;
					margin-right: 20px;
					cursor: pointer;

					.headimg {
						border-radius: 50%;
						border-width: 1px;
						border-style: solid;
						margin-left: -10px;
						width: 32px;
						height: 32px;
						object-fit: cover;
						object-position: center;
						background-color: @color-gray-1;
						box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
						cursor: pointer;
					}
				}

				.user-head {
					width: 80px;
					height: 50px;
					border-radius: 100px;
					border: 1px solid @color-gray-1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					transition: all 0.2s;
					cursor: pointer;

					.headimg {
						margin-left: 5px;
						width: 42px;
						height: 42px;
						border-radius: 50%;
						border-width: 2px;
						border-style: solid;
						background-color: @color-gray-1;
						object-fit: cover;
						object-position: center;
					}

					.icon {
						font-size: 14px;
						margin-right: 5px;
						color: @color-gray-2;
						transition: all 0.2s;
					}
				}

				.user-head.active {
					// border: 1px solid #000;
					box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

					.icon {
						color: @color-brand;
					}
				}
			}
		}
	}

	.content {
		height: auto;
		margin: 0 20px;
		background-color: #FFFFFF;
		border-radius: 10px 10px 0 10px;
		overflow-y: auto;

		.col-header {
			display: flex;
			align-items: center;
			height: 48px;
			padding: 0 20px;
			color: #000;

			.col-title {
				display: flex;
				align-items: center;

				.icon {
					width: 16px;
				}

				.text {
					margin-left: 5px;
					font-size: 14px;
					font-weight: bold;
				}
			}
		}
	}

	.sider-container {
		width: 100%;
		height: 100vh;
		position: relative;
		padding-top: 80px;
		overflow: hidden;
		box-sizing: border-box;

		.logo-box {
			padding: 30px 38px 0;
			overflow: hidden;
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
		}

		.object-list {
			width: 100%;
			height: 100%;
			display: flex;
			padding-bottom: 60px;
			flex-direction: column;
			align-items: flex-start;
			overflow-y: auto;


			.object-item {
				margin-top: 16px;
				padding: 0 20px;
				width: 100%;
				height: 88px;
				cursor: pointer;
				transition: all 0.2s;

				.object-item-content {
					padding: 0 15px;
					border-radius: 7px;
					line-height: 88px;
					text-align: center;
					color: rgba(255, 255, 255, 0.8);
					font-weight: bold;
					font-size: 14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					transition: all 0.2s;
					position: relative;

					.more {
						width: 24px;
						height: 28px;
						background: url(../../assets/img/more.svg) no-repeat center center rgba(0, 0, 0, 0);
						background-size: 3px auto;
						border-radius: 0 0 0 10px;
						position: absolute;
						top: 0;
						right: 0;
						opacity: 0;
						transition: all 0.2s;
					}

					.active,
					.more:hover {
						background-color: rgba(0, 0, 0, 0.1);
						opacity: 1;
					}
				}

				.object-item-content:hover {
					box-shadow: none;

					.more {
						opacity: 1;
					}
				}
			}

			.object-item-pick {
				padding-right: 0;

				.object-item-content {
					border-radius: 10px 0 0 10px;
				}
			}

			.object-add {
				margin-top: 16px;
				padding: 0 20px;
				width: 100%;
				height: 88px;
				cursor: pointer;

				.object-item-content {
					padding: 0 15px;
					background-color: @color-grayish-blue;
					border-radius: 7px;
					line-height: 88px;
					color: #fff;
					font-size: 14px;
					display: flex;
					align-items: center;
					justify-content: center;

					.icon {
						margin-right: 5px;
					}
				}
			}
		}

		.object-list-box {
			margin-top: 15px;
		}
	}

	.object-members-list {
		height: calc(100vh - 112px);
		padding: 0 12px 20px;
		overflow-y: auto;

		.object-members-item:first-child {
			margin-top: 0;
		}

		.object-members-item {
			margin-top: 10px;
			height: 52px;
			border: 1px solid @color-gray-1;
			border-radius: 100px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			cursor: pointer;
			transition: all 0.2s;

			.members-head {
				font-size: 0;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				width: 62%;

				.members-headimg {
					margin-left: 5px;
					width: 42px;
					height: 42px;
					border-radius: 50%;
					object-fit: cover;
					object-position: center;
					background-color: @color-gray-1;
					border-width: 2px;
					border-style: solid;
				}

				.members-name {
					display: inline-block;
					vertical-align: middle;
					margin-left: 10px;
					font-size: 14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.members-jobs {
				width: 38%;
				margin-right: 20px;
				font-size: 13px;
				text-align: right;
				color: @color-gray-2;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.object-members-item:hover {
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
		}
	}

	.object-dynamic-list {
		height: calc(100vh - 112px);
		padding: 0 12px 20px;
		overflow-y: auto;

		.object-dynamic-item:first-child {
			margin-top: 0;
		}

		.object-dynamic-item {
			margin-top: 10px;
			min-height: 46px;
			padding: 11px 0;
			height: 46px;
			border: 1px solid @color-gray-1;
			border-radius: 10px;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			cursor: pointer;
			transition: all 0.2s;

			.dynamic {
				width: 86%;
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				font-size: 14px;
				color: @color-gray-4;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.icon {
				color: #EAEAEA;
				font-size: 12px;
				margin-right: 6px;
				margin-top: 5px;
			}
		}

		.object-dynamic-item-remind {
			.dynamic {
				color: @color-red;
			}
		}

		.object-dynamic-item:hover {
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
		}

		.object-dynamic-item-check {
			height: auto;
			box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

			.dynamic {
				height: auto;
				white-space: normal;
				overflow: auto;
				text-overflow: clip;
			}

			.icon {
				color: @color-gray-3;
				transform: rotate(0.25turn);
			}
		}

	}

	.object-document-list {
		height: calc(100vh - 112px);
		padding: 0 12px 20px;
		overflow-y: auto;

		.object-document-item {
			width: 100%;
			height: calc(100vh/4 - 20px);
			min-height: 160px;
			border-radius: 15px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-wrap: wrap;
			margin-top: 12px;
			cursor: pointer;
			transition: all 0.2s;
			overflow: hidden;

			.document-icon {
				width: 36px;
				height: 36px;

				img {
					max-width: 100%;
					height: 100%;
				}
			}

			.document-text {
				width: 100%;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.8);
				text-align: center;
				margin-top: 7px;
			}
		}

		.blur1 {
			background: url(../../assets/img/blur1.png) center center;
			background-size: cover;
			cursor: no-drop;
		}

		.blur2 {
			background: url(../../assets/img/blur2.png) center center;
			background-size: cover;
			cursor: no-drop;
		}

		.object-document-row {
			display: flex;
			justify-content: center;
			align-content: center;
			flex-wrap: wrap;
			margin-top: 12px;

			.object-document-item-half:first-child {
				padding-right: 6px;
			}

			.object-document-item-half:last-child {
				padding-left: 6px;
			}

			.object-document-item-half {
				width: 50%;

				.object-document-item {
					margin-top: 0px;
					height: calc(100vh/4 - 70px);
					min-height: 120px;
				}
			}
		}

		.object-document-row:first-child {
			margin-top: 0px;
		}
	}
}

// 脑暴管理

.drawer-header {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: -10px;
	padding: 0 15px;

	.add-btn {
		padding: 0 14px;
		height: 35px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		border-radius: 100px;
		font-weight: bold;
		cursor: pointer;
		transition: all 0.2s;

		img {
			margin-right: 5px;
		}
	}

	.display-style {
		width: 100px;
		height: 36px;
		border: 1px solid @color-gray-1;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		margin-left: 30px;

		.grid {
			width: 50%;
			height: 100%;
			background: url(../../assets/img/grid_white.svg) center center no-repeat @color-brand;
			background-size: 15px;
			cursor: pointer;
		}

		.list {
			width: 50%;
			height: 100%;
			background: url(../../assets/img/list.svg) center center no-repeat white;
			background-size: 15px;
			cursor: pointer;
		}
	}
}

.brain-think-grid {
	margin-top: 24px;
	padding: 0 15px;

	.brain-think-item {
		position: relative;
		padding: 18px;
		box-sizing: border-box;
		height: 185px;
		border-radius: 8px;
		border: 1px solid @color-gray-1;
		transition: box-shadow 0.2s;
		cursor: pointer;

		.brain-think-title {
			font-size: 16px;
			font-weight: bold;
			color: #323232;
			margin-bottom: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.brain-think-desc {
			height: 65px;
			color: @color-gray-4;
			font-size: 14px;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			line-clamp: 3;
			-webkit-box-orient: vertical;
			transition: all 0.2s;
			opacity: 0.6;
		}

		// 打开链接和下载附件按钮
		.outside-box {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 30px 0;
			background-color: white;
			opacity: 0;
			transition: all 0.2s;

			.open {
				border-radius: 5px;
				border: none;
				text-shadow: none;
			}
		}

		// 编辑按钮
		.operate {
			position: absolute;
			bottom: 8px;
			right: 10px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			transition: all 0.2s;
			opacity: 0;

			.btn {
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				overflow: hidden;
				cursor: pointer;
				background-color: white;
				transition: all 0.2s;
			}

			.btn:hover {
				background-color: @color-gray-0;
			}
		}
	}

	.brain-think-item:hover {
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07);
		background: none;

		.outside-box {
			opacity: 1;
		}

		.operate {
			opacity: 1;
		}
	}

	// 链接类型的文件
	.link {
		background: url(../../assets/img/link.svg) no-repeat center 74px;
		background-size: 82px auto;
	}

	// 附件类型的文件
	.file {
		background: url(../../assets/img/download.svg) no-repeat center 74px;
		background-size: 98px auto;
	}
}

.brain-think-list {
	margin-top: 24px;
	padding: 0 15px;

	.brain-think-item {
		position: relative;
		padding: 0 18px;
		padding-right: 120px;
		margin-bottom: 12px;
		box-sizing: border-box;
		height: 52px;
		border-radius: 8px;
		border: 1px solid @color-gray-1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		transition: all 0.2s;
		cursor: pointer;

		.brain-think-title {
			max-width: 20%;
			min-width: 70px;
			font-size: 16px;
			font-weight: bold;
			color: #323232;
			margin-right: 30px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.brain-think-desc {
			max-width: 80%;
			color: @color-gray-4;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			transition: all 0.2s;
			opacity: 0.6;
			margin: 0;
		}

		.operate {
			position: absolute;
			top: 9px;
			right: 12px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			transition: all 0.2s;
			opacity: 0;

			.btn {
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 8px;
				overflow: hidden;
				cursor: pointer;
				background-color: white;
				transition: all 0.2s;
			}

			.btn:hover {
				background-color: @color-gray-0;
			}
		}
	}

	// 链接类型的文件
	.link {
		background: url(../../assets/img/link_list.svg) no-repeat 16px center;
		background-size: 22px auto;
		padding-left: 56px;
		padding-right: 180px;

		.brain-think-title {
			width: 100%;
			max-width: 100%;
		}

		.operate {
			right: 125px;
		}

		.open {
			border-radius: 5px;
			border: none;
			text-shadow: none;
			position: absolute;
			top: 9px;
			right: 10px;
			opacity: 0;
		}
	}

	// 附件类型的文件
	.file {
		background: url(../../assets/img/download_list.svg) no-repeat 16px center;
		background-size: 24px auto;
		padding-left: 56px;
		padding-right: 180px;

		.brain-think-title {
			width: 100%;
			max-width: 100%;
		}

		.operate {
			right: 125px;
		}

		.open {
			border-radius: 5px;
			border: none;
			text-shadow: none;
			position: absolute;
			top: 9px;
			right: 10px;
			opacity: 0;
		}
	}

	.brain-think-item:hover {
		box-shadow: 0 8px 16px rgba(0, 0, 0, 0.07);

		.operate,
		.open {
			opacity: 1;
		}
	}
}

// 个人中心弹框
.user-manage {
	width: 169px;
	background-color: white;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	overflow: hidden;

	.user-name,
	.user-name:hover {
		width: 100%;
		height: 45px;
		color: white;
		font-size: 16px;
		font-weight: bold;
		line-height: 45px;
		text-align: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		box-shadow: none !important;
		cursor: pointer;
	}

	.user-list {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 45px;
		margin: 0 12px;
		border-bottom: 1px solid @color-gray-1;
		cursor: pointer;

		.left {
			font-size: 13px;
			color: @color-gray-2;
		}

		.right {
			font-size: 13px;
			color: @color-gray-4;
		}
	}

	.login-out {
		height: 45px;
		line-height: 45px;
		text-align: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		font-size: 14px;
		cursor: pointer;
	}
}

// 在线用户弹框
.online-box {
	width: 169px;
	background-color: white;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	overflow: hidden;
	padding: 10px 12px 10px;

	.online {
		font-size: 13px;
		color: @color-gray-2;
		margin-bottom: 5px;
	}

	.online-list {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 40px;

		.headimg {
			border-radius: 50%;
			border-width: 1px;
			border-style: solid;
			width: 32px;
			height: 32px;
			object-fit: cover;
			object-position: center;
			background-color: @color-gray-1;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
			margin-right: 8px;
			cursor: pointer;
		}

		.name {
			font-size: 13px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}

// 查看脑暴弹框
.brain-think-content {
	font-size: 15px;
	color: @color-gray-4;
	margin: 10px auto 30px;
	min-height: 150px;
}

.operate {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.btn {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
		overflow: hidden;
		cursor: pointer;
		background-color: white;
		transition: all 0.2s;
	}

	.btn:hover {
		background-color: @color-gray-0;
	}
}

// 登录绑定邮箱弹框
.binding-email-box {
	.ant-modal-content {
		border-radius: 20px !important;

		.ant-modal-body {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0;
		}

		.binding-email-logo {
			height: 420px;
			width: 50%;
			background: url(../../assets/img/binding_email.png) left center white no-repeat;
			background-size: 50% 100%;
			background-size: cover;
			border-radius: 20px 0 0 20px;
			overflow: hidden;
		}

		.binding-email-content {
			height: 420px;
			width: 50%;
			padding: 0 48px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-wrap: wrap;

			.binding-email-title {
				font-size: 24px;
				margin-bottom: 40px;
				color: @color-brand;
				margin-top: -20px;
			}

			.auth-code {
				width: 100%;
				margin-top: 12px;
				display: flex;
				justify-content: space-between;
				align-content: center;

				.auth-code-input {
					width: 100%;
					margin-right: 10px;
				}

				.get-auth-code {
					width: 94px;
					height: 100%;
					background-color: @color-brand;
					font-size: 13px;
					font-weight: normal;
				}
			}

			.binding-email-btn {
				width: 100%;
				height: 40px;
				margin-top: 30px;
			}
		}
	}
}

// 查看成员弹框
.check-member-box {
	text-align: center;
	padding: 8px;

	.member-head {
		width: 90px;
		height: 90px;
		border-radius: 50%;
		border-width: 3px;
		border-style: solid;
		object-fit: cover;
		object-position: center;
		background-color: @color-gray-1;
		margin: 0 auto 12px;
	}

	.member-name {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 3px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.member-jobs {
		font-size: 14px;
		color: @color-gray-2;
		margin-bottom: 28px;
	}

	.member-join-date,
	.inviter {
		font-size: 16px;
		color: @color-gray-4;
		margin-bottom: 3px;
	}

	.member-manage {
		height: 42px;
		background-color: @color-brand;
		padding: 0 20px;
		font-size: 15px;
		margin-top: 36px;
	}
}

// 邀请成员弹框
.invite-member {

	.brain-think-title {
		font-size: 16px;
		font-weight: bold;
		padding: 0 24px;
	}

	.ant-modal-body {
		padding: 24px 0 0;
	}

	.invite-email {
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-top: 20px;
		margin-bottom: 24px;
		padding: 0 24px;

		.invite-input {
			margin-right: 10px;
		}

		.invite-btn {
			height: 40px;
		}
	}

	.member-list {
		max-height: 300px;
		overflow-y: auto;
		padding: 18px 24px 0;
	}

	.member-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 18px;
		box-sizing: border-box;
		overflow: hidden;

		.head-box {
			max-width: 52%;
			display: flex;
			justify-content: flex-start;
			align-content: center;

			.members-headimg {
				width: 42px;
				height: 42px;
				border-radius: 50%;
				object-fit: cover;
				object-position: center;
				background-color: @color-gray-1;
				border-width: 2px;
				border-style: solid;
				margin-left: 3px;
			}

			.members-name {
				display: block;
				height: 42px;
				line-height: 42px;
				margin-left: 10px;
				font-size: 14px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.inviting {
			height: 42px;
			font-size: 14px;
			color: @color-gray-4;
			line-height: 42px;
		}

		.control {
			display: flex;
			justify-content: flex-end;
			align-content: center;

			.delete {
				width: 42px;
				height: 42px;
				background: url(../../assets/img/close.svg) center center no-repeat;
				background-size: 12px;
				cursor: pointer;
			}
		}

		.ant-form {
			.ant-form-item {
				margin-bottom: 0;
				margin-top: 5px;

				.ant-select-selection-item {
					text-align: right;
					margin-right: 10px;
					color: @color-gray-4;
				}

				.ant-select-arrow {
					margin-top: -8px;
				}
			}
		}
	}

	.me {
		margin-bottom: 0;
		border-bottom: 1px dashed #e6e6e6;
		padding: 0 24px 18px;
	}
}

// 消息弹框

.message-modal {
	.brain-think-title {
		font-size: 16px;
		font-weight: bold;
		padding: 0 24px;
	}

	.ant-modal-body {
		padding: 24px 0 0;
	}

	.message-list {
		height: 340px;
		padding: 0px 36px 20px;
		overflow-y: auto;
		margin-top: 20px;

		.message-item {
			margin-bottom: 10px;
			padding: 0 12px;
			min-height: 52px;
			border: 1px solid @color-gray-1;
			border-radius: 14px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			transition: all 0.2s;

			.message-box {
				width: 62%;
				font-size: 14px;
				padding: 14px 0;

				.message-new {
					color: @color-red;
					margin-right: 8px;
				}

				.members-text {
					display: inline-block;
					vertical-align: middle;
					margin-left: 10px;
					font-size: 14px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.message-btn-box {
				height: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				text-align: right;
				color: @color-gray-2;
				font-size: 14px;

				.lose {
					border-radius: 100px !important;
					background-color: @color-gray-2;
					margin-right: 8px;
				}

				.pass {
					border-radius: 100px !important;
					background-color: @color-brand;
				}
			}
		}
	}
}

// 项目管理下拉框

.project-manage {
	background-color: white;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);

	.project-manage-item {
		height: 34px;
		padding: 0 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		color: @color-gray-4;
		border-bottom: 1px solid @color-gray-1;
		cursor: pointer;
	}

	.project-manage-item :last-child {
		border: none;
	}

	.project-manage-item:hover {
		background-color: @color-gray-1;
	}
}

.rename {
	.ant-popover-message-title {
		padding-left: 0 !important;
	}
}
@primary-color: #FFC400;