@import '../../assets/mixin.less';

// 左侧栏默认样式
.ant-menu-inline .ant-menu-item::after {
    left: 0 !important;
    right: auto !important;
    border-color: @color-red  !important;
    border-width: 5px !important;
}

.ant-menu-item {
    height: 30px !important;
    line-height: 30px !important;
}

.ant-menu-item-selected {
    background-color: white !important;
    color: black !important;
}

// 颜色

.green-border {
    border-color: @color-green;
}

.red-border {
    border-color: @color-red;
}

.purple-border {
    border-color: @color-purple;
}

.green-color {
    color: @color-green  !important;
}

.red-color {
    color: @color-red  !important;
}

.purple-color {
    color: @color-purple  !important;
}

.grayish-blue-color {
    color: @color-grayish-blue  !important;
}

.blue-color {
    color: @color-blue  !important;
}


// 页面样式开始
.myself-info-page {
    background-color: @color-brand;

    .layout-page {
        margin: 0 auto;
        padding: 0 40px;
        max-width: 1400px;

        .ant-layout-header {
            height: 80px;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: @color-brand;

            .logo-box {
                width: 120px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .login-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .logout {
                    height: 34px;
                    color: white;
                    background: none;
                    opacity: 0.5;
                    transition: all 0.2;
                }

                .logout:hover {
                    opacity: 1;
                }

                .loging {
                    height: 34px;
                    color: white;
                    border: 1px solid white !important;
                    background: none;
                }

                .loging:hover {
                    color: @color-brand;
                    border: none;
                    background-color: white;
                }
            }
        }

        .ant-layout-content {
            border-radius: 30px 30px 0 0;
            background-color: white;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .ant-layout-sider {
                height: 100%;
                overflow: hidden;

                .left-menu {
                    padding-top: 52px;

                    .ant-menu {
                        background-color: white !important;
                    }
                }
            }

            .ant-layout-content {
                height: 100%;
                float: right;

                .content-box {
                    width: 100%;
                    color: black;
                    padding: 40px 60px;

                    .set-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 15px;

                        .set-title {
                            font-size: 13px;
                            color: @color-gray-3;
                            margin-right: 40px;
                        }

                        .set-content {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 14px;
                            font-weight: 400;
                            color: black;
                        }

                        .set-edit {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background: url(../../assets/img/edit-red.svg) no-repeat center center;
                            background-size: 10px 10px;
                            cursor: pointer;
                            transition: all 0.2s;
                            margin-left: 20px;
                            opacity: 0;
                        }

                        .set-edit:hover {
                            background-color: @color-gray-1;
                            opacity: 1;
                        }
                    }

                    .set-header {
                        margin-bottom: 26px;
                    }

                    .set-list:hover {
                        .set-edit {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}


// 弹框样式
.ant-modal-content {
    border-radius: 6px !important;
}

.ant-modal-header {
    padding: 24px 24px 10px !important;
}

.ant-modal-header,
.ant-modal-footer {
    border: none !important;
}

.ant-modal-close {
    width: 30px;
    height: 30px;
    left: -12px;
    top: -12px !important;
    background-color: white !important;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .ant-modal-close-x {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 12px;
            height: 13px;
        }
    }
}

// 更换邮箱
.change-email-box {
    .modal-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .auth-code {
        width: 100%;
        height: 40px;
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .auth-code-input {
            width: 100%;
            margin-right: 10px;
        }

        .get-auth-code {
            width: 94px;
            height: 100%;
            background-color: @color-brand;
            font-size: 13px;
            font-weight: normal;
        }
    }

    .binding-email-btn {
        width: 100%;
        height: 40px;
        margin-top: 30px;
    }
}

// 修改身份
.post-list {
    .post-item {
        width: 80%;
        margin: 0 auto 10px;
        height: 52px;
        color: white;
        border-radius: 8px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        cursor: pointer;
    }

    .administrator {
        color: @color-blue;
        border: 1px solid @color-blue;
    }

    .pm {
        color: @color-red;
        border: 1px solid @color-red;
    }

    .designer {
        color: @color-purple;
        border: 1px solid @color-purple;
    }

    .developer-f {
        color: @color-green;
        border: 1px solid @color-green;
    }

    .developer-b {
        color: @color-green;
        border: 1px solid @color-green;
    }

    .administrator:hover,
    .administrator-check {
        font-weight: bold;
        color: white;
        background-color: @color-blue;
    }

    .pm:hover,
    .pm-check {
        font-weight: bold;
        color: white;
        background-color: @color-red;
    }

    .designer:hover,
    .designer-check {
        font-weight: bold;
        color: white;
        background-color: @color-purple;
    }

    .developer-f:hover,
    .developer-f-check {
        font-weight: bold;
        color: white;
        background-color: @color-green;
    }

    .developer-b:hover,
    .developer-b-check {
        font-weight: bold;
        color: white;
        background-color: @color-green;
    }
}
@primary-color: #FFC400;