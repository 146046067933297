@import '../../assets/mixin.less';

// 公用样式弹框样式
.ant-modal-content {
	border-radius: 6px !important;
}

.ant-modal-close {
	width: 30px;
	height: 30px;
	left: -12px;
	top: -12px !important;
	background-color: white !important;
	border-radius: 50%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

	.ant-modal-close-x {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 12px;
			height: 13px;
		}
	}
}

// 弹框标题
.modal-title-box {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.modal-title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 24px;
	}

	.modal-title-explain {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 24px;

		.head {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			object-fit: cover;
			object-position: center;
			margin-right: 8px;
			border-width: 1px;
			border-style: solid;
		}

		.name {
			font-size: 12px;
			margin-right: 16px;
		}

		.date {
			font-size: 12px;
			color: @color-gray-2;
		}
	}
}

// 颜色

.green-border {
	border-color: @color-green;
}

.red-border {
	border-color: @color-red;
}

.purple-border {
	border-color: @color-purple;
}

.green-color {
	color: @color-green  !important;
}

.red-color {
	color: @color-red  !important;
}

.purple-color {
	color: @color-purple  !important;
}

.grayish-blue-color {
	color: @color-grayish-blue  !important;
}

.yellow {
	background-color: @color-yellow  !important;
}

.blue {
	background-color: @color-blue  !important;
}

.red {
	background-color: @color-red  !important;
}

.purple {
	background-color: @color-purple  !important;
}

.green {
	background-color: @color-green  !important;
}

.grayish-blue {
	background-color: @color-grayish-blue  !important;
}

.yellow:hover {
	box-shadow: 0 6px 12px rgba(255, 196, 0, 0.5);
}

.blue:hover {
	box-shadow: 0 6px 12px rgba(64, 93, 255, 0.5);
}

.red:hover {
	box-shadow: 0 6px 12px rgba(233, 82, 63, 0.5);
}

.purple:hover {
	box-shadow: 0 6px 12px rgba(106, 67, 237, 0.5);
}

.green:hover {
	box-shadow: 0 6px 12px rgba(53, 193, 85, 0.5);
}

.grayish-blue:hover {
	box-shadow: 0 6px 12px rgba(43, 44, 70, 0.5);
}


// 页面样式开始
.layout-page {
	background-color: white !important;
	color: white;
}

.index-page {
	min-width: 1000px;
	background-color: white !important;
	color: white;

	.ant-layout-header {
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: @color-brand;

		.logo-box {
			width: 108px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			overflow: hidden;

			img{
				width: 100%;
				height: auto;
			}
		}

		.login-box {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.logout {
				height: 34px;
				color: white;
				background: none;
				opacity: 0.5;
				transition: all 0.2;
			}

			.logout:hover {
				opacity: 1;
			}

			.loging {
				height: 34px;
				color: white;
				border: 1px solid white !important;
				background: none;
			}

			.loging:hover{
				color: @color-brand;
				border: none;
				background-color: white;
			}
		}
	}

	.content1 {
		width: 100%;
		height: 600px;
		background: url(../../assets/img/vein.png) repeat white;
		background-size: 150px auto;
		position: relative;
		overflow: hidden;

		.content1-h1 {
			width: 100%;
			height: 85px;
			font-size: 46px;
			text-align: center;
			margin-top: 62px;
		}

		.content1-h3 {
			width: 100%;
			height: 40px;
			text-align: center;

			img {
				width: 270px;
				height: auto;
			}
		}

		.preview-box {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 420px;
			background: url(../../assets/img/preview.png) no-repeat bottom center;
			background-size: 930px auto;
			display: flex;
			justify-content: center;
			align-items: flex-end;

			.enter-btn {
				width: 135px;
				height: 45px;
				margin-bottom: 45px;
				font-size: 15px;
				font-weight: normal;
				border-radius: 9px !important;
				background-color: @color-brand;
				box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
			}

			.enter-btn:hover {
				box-shadow: 0 10px 15px rgba(0, 0, 0, 0.35);
			}
		}
	}

	.content-h1 {
		width: 100%;
		line-height: 1.3;
		font-size: 40px;
		text-align: center;
		margin-top: 78px;
		margin-bottom: 25px;
		font-weight: normal;
	}

	.content-h3 {
		width: 100%;
		font-size: 20px;
		text-align: center;
		font-weight: 200;
		color: rgba(0, 0, 0, 0.6);

		span {
			font-weight: 400;
			color: rgba(0, 0, 0, 1);
		}
	}

	.start-use-btn {
		width: 135px;
		height: 45px;
		margin-bottom: 45px;
		font-size: 15px;
		font-weight: bold;
		border-radius: 9px !important;
		color: rgba(0, 0, 0, 0.6);
		background-color: rgba(255, 255, 255, 0.3);
	}

	.start-use-btn:hover {
		color: rgba(0, 0, 0, 0.6);
		background-color: rgba(255, 255, 255, 0.3);
		box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
	}

	.content2 {
		width: 100%;
		height: 700px;
		background: white;
		position: relative;
		overflow: hidden;

		.funcimg {
			width: 1000px;
			height: 380px;
			margin: 0 auto;
			background: url(../../assets/img/funcimg.png) no-repeat center center;
			background-size: 100% auto;
		}
	}

	.content3 {
		width: 100%;
		height: 600px;
		background-color: #FFD446;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		.leftbox {
			width: 500px;
			height: 100%;
			display: flex;
			align-content: center;
			justify-content: center;
			flex-wrap: wrap;

			.btn-box {
				width: 100%;
				display: flex;
				align-content: center;
				justify-content: center;
				margin-top: 26px;
			}
		}

		.rightbox {
			width: 500px;
			height: 100%;
			background: url(../../assets/img/taskimg.png) no-repeat center center;
			background-size: 420px auto;
		}
	}

	.content4 {
		width: 100%;
		height: 600px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		.leftbox {
			width: 500px;
			height: 100%;
			background: url(../../assets/img/dynamic-img.png) no-repeat center center;
			background-size: 420px auto;
		}

		.rightbox {
			width: 500px;
			height: 100%;
			display: flex;
			align-content: center;
			justify-content: center;
			flex-wrap: wrap;

			.btn-box {
				width: 100%;
				display: flex;
				align-content: center;
				justify-content: center;
				margin-top: 26px;

				.start-use-btn {
					background-color: rgba(0, 0, 0, 0.06)
				}
			}
		}
	}

	.content5 {
		height: 1120px;
		background: url(../../assets/img/undraw.png) no-repeat center 45px #7CB8FF;
		background-size: 1000px auto;
		overflow: hidden;

		.btn-box {
			width: 100%;
			display: flex;
			align-content: center;
			justify-content: center;
			margin-top: 26px;

			.start-use-btn {
				background-color: rgba(0, 0, 0, 0.06)
			}
		}
	}

	.content6 {
		height: 800px;
		overflow: hidden;
		margin-top: -330px;

		.content-h2 {
			width: 100%;
			font-size: 24px;
			text-align: center;
			font-weight: normal;
			margin-bottom: 26px;
		}

		.report-img {
			width: 1000px;
			height: 514px;
			margin: 0 auto;
			background: url(../../assets/img/report.png) no-repeat center center;
			background-size: 800px auto;
		}

		.btn-box {
			width: 100%;
			display: flex;
			align-content: center;
			justify-content: center;
			margin-top: 50px;

			.start-use-btn {
				background-color: rgba(0, 0, 0, 0.06)
			}
		}
	}

	.page-footer {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: rgba(255, 255, 255, 0.6);
		font-size: 12px;
		font-weight: lighter;
		background-color: @color-brand;
	}
}


// 登录绑定邮箱弹框
.binding-email-box {
	.ant-modal-content {
		border-radius: 20px !important;

		.ant-modal-body {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			padding: 0;
		}

		.login-logo {
			height: 420px;
			width: 50%;
			background: url(../../assets/img/login.png) left center white no-repeat;
			background-size: 50% 100%;
			background-size: cover;
			border-radius: 20px 0 0 20px;
			overflow: hidden;
		}

		.binding-email-content {
			height: 420px;
			width: 50%;
			padding: 0 48px;
			display: flex;
			justify-content: center;
			align-content: center;
			flex-wrap: wrap;

			.login-title {
				font-size: 24px;
				margin-bottom: 10px;
				color: @color-brand;
				margin-top: -6px;
			}

			.login-p {
				font-size: 14px;
				text-align: center;
			}

			.login-QRcode {
				width: 210px;
				height: auto;
				border-radius: 35px;
				padding: 5px;
				margin-top: 8px;
				border: 1px solid @color-gray-1;
				overflow: hidden;

				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
@primary-color: #FFC400;